// @import '../pages/ConfigureSteps/style.scss';
.my-account-head {
    height: 115px;
    background: #F0F0F0;
    padding: 30px 80px;
    font-weight: 400;
    font-size: 26px;
    line-height: 30px;
    position: relative;
    &.view-account {
        padding-left: 0px;
        background-color: unset;
        margin-left: 30px;
        padding-top: 40px;
        @media (max-width: 850px) {
            margin: 0;
        }
    }
    @media (max-width: 1100px) {
        padding: 30px 50px;
    }
    .static-avatar img{
        width:80px !important;
    }
    .profile-picture {
        position: absolute;
        width: 140px;
        height: 140px;
        border-radius: 140px;
        display: flex;
        justify-content: center;
        align-items: center;
        left: 50%;
        transform: translateX(-50%);
        // border: 2px solid #00CDFF;
        .avatar {
            margin: 0;
            width: 140px;
            height: 140px;
            img {
                width: 140px;
                height: 140px !important;
            }
        }
        img{
            border-radius: 140px;
        }
        .edit-icon {
            width: 34px;
            height: 34px;
            border-radius: 34px;
            background: #101257;
            position: absolute;
            display: flex;
            justify-content: center;
            align-items: center;
            right: 0;
            bottom: 0;
            img {
                width: 25px;
            }
        }
    }
}
.file-upload{
    display: contents;
}
.fileName-div{
    margin-top: 100px;
    display: flex;
    /* width: 100vh; */
    /* background: aqua; */
    justify-content: center;
    align-items: center;
}
.fileName-div-name{
    position: relative;
}
.fileName-div-cross{
    position: absolute;
    margin-left: 3px;
    top: -5px;
    cursor: pointer;
}
.file-save-btn{
    background: hsl(212, 89%, 53%);
    border-radius: 4px;
    border: none;
    color: white;
    height: 30px;
    margin-left: 10px;
    padding: 0 15px;
}
.account-boxes {
    display: flex;
    flex-direction: column;
    gap: 35px;
}

.account-container {
    margin: 100px 30px;
    width: calc(100% - 60px);
    border: 1px solid #D6D6D6;
    border-radius: 20px;
    //min-height: 80vh;
    padding: 50px 80px;
    z-index: 4;
    &.update-screen {
        margin: 0 30px;
        width: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        @media (max-width: 850px) {
            margin: 0px;
        }
    }
    &.view-account {
        margin: 0px 30px;
        padding: 26px 40px;
        min-height: unset;
        @media (max-width: 850px) {
            margin: 0px;
        }
        .head {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            h4 {
                font-weight: 400;
                font-size: 18px;
                line-height: 21px;
                color: #000000;
                margin: 0;
            }
            button {
                border: 1px solid #C0C0C0;
                border-radius: 20px;
                background: unset;
                font-weight: 400;
                font-size: 16px;
                line-height: 19px;
                color: #656565;
                padding: 7px 20px;
            }
        }

        .editable-information {
            display: flex;
            gap: 150px;
            margin-top: 20px;
            @media (max-width: 850px) {
                flex-wrap: wrap;
                gap: 40px;
            }
            .editable-information-box {
                display: flex;
                flex-direction: column;
                gap: 30px;
                width: 150px;
                .editable-information-field {
                    .label {
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 16px;
                        color: #7A7A7A;
                    }
                    .value {
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 19px;
                        color: #000000;
                        margin-top: 13px;
                    }
                }
            }
        }

    }
    .disable-input{
        background-color: #F0F0F0;
        cursor: not-allowed
    }
    .secondary-value{
        display: flex;
        column-gap: 150px;
        row-gap: 15px;
        flex-wrap: wrap;
    }
    @media (max-width: 1100px) {
        padding: 50px 10px;
        margin: 100px 0px;
        width: 100%;
    }
    .custom-save-button {
        width: 157px;
        height: 43px;
        background: hsl(212, 89%, 53%);
        border-radius: 4px;
        border: none;
        margin-top: 50px;
    }
    .custom-cancel-button {
        width: 157px;
        height: 43px;
        border-radius: 4px;
        border: none;
        margin-top: 50px;
        margin-right: 20px;
        background: transparent;
        border: 1px solid red;
        color: red;
    }
}

form div {
    z-index: 4;
}
#react-select-4-listbox{
    z-index: 9999;
}
.active{
    border-bottom: 1px solid;
}
.tab-header-container{
    display: flex;
}
.tab-header-container ul{
    margin-left: 30px;
}
.crm-field-table{
    width: 80%;
}
.crm-field-table table tbody td{
    padding-top: 10px;
}
.crm-field-table-tr td{
    padding-top: 10px !important;
}
.arrow-div{
    border-left: 2px solid black;
border-bottom: 2px solid black;
width: 358px;
height: 43px;
position: absolute;
z-index: 9;
    bottom: -43px;
}
.html-arrow{
    position: absolute;
    right: 74px;
    bottom: -64px;
    font-size: 30px;
    font-weight: 100;
    color: black;
}
.weighted-btn-divv{
    display: flex;
    justify-content: flex-end;
    position: absolute;
    z-index: 9;
    bottom: -61px;
    right: -144px;
}
.edit-primary-container{
    position: relative;
    margin-bottom: 0 !important;
    margin: 0 auto;
    width: 520px;
}
.edit-parent-primary{
    margin-bottom: 50px;
}
.edit-submit-btn{
    padding-bottom: 40px;
}
.quota-edit-view{
    .editable-information{
        width: 45%;
    }
}