.main-app-container {
    margin: 0px 50px;
    position: relative;
    &.gradiant-image {
        margin: 0;

        &::after {
        background: url("../../public/images/bottom.svg");
        background-position: 50%;
        background-repeat: no-repeat;
        background-size: cover;
        bottom: 0;
        content: "";
        height: 350px;
        opacity: 1;
        position: fixed;
        width: 100%;
        z-index: 1;
        }
        @media (max-width: 768px) {
            margin: 0px 10px;
        }
    }

    @media (max-width: 1100px) {
        margin: 0px 15px;
    }
    &.edit-account {
        margin: 0px;
    }
}
.sidebar-avatar {
    width: 40px;
    border-radius: 40px;
}
.header-avatar {
    width: 30px;
    border-radius: 30px;
}
.avatar {
    width: 30px;
    height: 30px;
    // background-color: #00CDFF;
    margin-right: 10px;
    img {
        margin: 0 !important;
        height: auto !important;
    }
}
.without-login-header {
    width: 100%;
    height: 80px;
    position: relative;
    justify-content: center;
    z-index: 2;
    .user-container {
        position: absolute;
        right: 20px;
        color: #ffffff;
        
    }
    @media (max-width: 767px) {
        height: 60px;
        padding: 10px;
        justify-content: flex-start;
        padding-left: 30px;
        img {
            height: 30px;
        }
        .avatar {
            width: 30px;
            height: 30px;
            // background-color: #00CDFF;
            margin-right: 10px;
            img {
                height: auto;
            }
        }
    }
}
.slider-avatar img{
    width: 30px;
    height: 30px !important;
    border-radius: 50%;
}

.page-message {
    h3 {
        font-weight: 500;
        font-size: 30px;
        text-align: center;
        color: #101257;
    }
    p {
        margin-top: 30px;
        font-weight: 400;
        font-size: 20px;
        text-align: center;
        color: #101257;
    }
}
.inner-sticky-header {
    &.remove-header-desktop {
        display: none;
        @media (max-width: 767px) {
            display: block;
        }
    }
    &.unstick-header {
        margin-bottom: 0px !important;
        display: none;
        @media (max-width: 767px) {
            display: block;
        }
    }
    @media (max-width: 1100px) {
        position: initial !important;
    }
}
.page-heading {
    margin-bottom: 20px;
    font-size: 36px;
    &.no-margin {
        margin-bottom: 10px;
    }
}
.mobile-header-menu {
    position: sticky;
    cursor: pointer;
    left: 10px;
    z-index: 1;
    display: none;
    width: 100%;
    margin: 0;
    left: 0;
    top: 0;
    padding: 20px;
    background-color: $primary-base;
    @media (max-width: 767px) {
        display: flex;
        justify-content: space-between;
        .right-section {
            display: flex;
            align-items: center;
            .my-account-box {
                margin-right: 10px;
                color: #ffffff;
            }
        }
        img {
            height: 30px;
            margin-left: 20px;
        }
    }
    svg {
        width: 30px;
        color: #ffffff;
    }
}

.sidebar-avatar {
    width: 26px !important;
}

.sidebar-footer svg {
    width: 17px !important;
}

.sidebar-footer span {
    font-size: 14px !important;
}
.sidebar-footer {
    border-top: 1px solid #9B9B9B66;
    padding: 14px 17px;
    span {
        text-transform: capitalize;
    }
}

.start-date.active, .end-date.active {
    background-color: #1C7FF2 !important;
}

.applyBtn {
    background-color: #1C7FF2 !important;
    width: 83px;
    //height: 26px;
    border-radius: 4px;
    border: 1px solid #1C7FF2;
}
.cancelBtn {
    background: unset !important;
    color: #1C7FF2;
}

@media (max-width: 767px) {
    .sidebar-footer {
        display: none !important;
    }
}
.loader-container {
    position: fixed;
    background: #000000a3;
    width: 100%;
    height: 100vh;
    z-index: 1100;
    display: flex;
    justify-content: center;
    align-items: center;
}
.cursor-pointer {
    cursor: pointer;
}
.page-subheading {
    margin-bottom: 20px;
    font-size: 12px;
}
.new-period-container {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    a {
        font-size: 14px;
        color: #DB4437;
    }
}
.custom-filter-button {
    border: 1px solid;
    border: 1px solid #A9A9A9;
    border-radius: 4px;
    min-width: 270px;
    text-align: left;
    position: relative;
    button {
        text-align: left;
        font-weight: 600;
        font-size: 13px;
        line-height: 15px;
        padding: 10px;
        background: none;
        border: none;
        width: 100%;
        &::after{
            position: absolute;
            right: 10px;
            top: 16px;
            display: inline-block;
            vertical-align: 0.255em;
            content: "";
            border-top: 0.3em solid;
            border-right: 0.3em solid transparent;
            border-bottom: 0;
            border-left: 0.3em solid transparent;
        }
    }
    .dropdown-item {
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        color: #000000;
        &:hover{
            background-color: #ffffff;
        }
    }
}
.config-custom-filter-button{
    .dropdown-menu.show{
        height: 200px;
        overflow: auto;
    }

}
.tooltip-custom .tooltip-inner {
    max-width: 500px;
    text-align: start;
  }
.config-user-btn{
    color: white !important;
}
.reminder-custom-filter-button{
    min-width: 291px;
}
.forecast-primary-field .custom-filter-button{
    min-width: 285px !important;
}
.close-date-dropdown{
    min-height: 35px;
}
.pipeline-field-dropdown{
    width: 320px;
}

.error {
    font-size: 13px;
    color: red;
    margin-top: 4px;
}

.date-filter {
    display: flex;
    justify-content: flex-end;
    margin: 20px 0px;
    align-items: center;
    gap: 20px;
    flex-wrap: wrap;
    @media (max-width: 554px) {
        &.date-filter-2 {
            flex-direction: column;
        }
    }
    &.flex-start {
        justify-content: flex-start;
    }
    .new-field {
        font-weight: 500;
        font-size: 12px;
        line-height: 14px;
        color: #101257;
        @media (max-width: 554px) {
            margin-left: 0px;
            margin-top: 10px;
        }
    }
    label {
        margin-right: 10px;
        font-weight: 600;
        font-size: 14px;
        line-height: 16px;
        color: #000000;
    }
    input {
        font-weight: 500;
        font-size: 12px;
        line-height: 20px;
        letter-spacing: -0.08px;
        width: 182px;
        border: 1px solid #A9A9A9;
        border-radius: 4px;
        height: 35px;
    }
    .date-picker-container {
        position: relative;
        .custom-datepicker {
            position: absolute;
            right: 0px;
        }
        .date-picker-dropdown {
            position: absolute;
            bottom: 0;
            opacity: 0;
            left: 0;
        }
        img {
            position: absolute;
            right: 10px;
            top: 13px;
        }
    }
}
.over-date-filter{
    display: block;
}
.close-date-overview{
    display: flex;
    flex-direction: column;
    gap: 0;
    align-items: unset;
}
#customdatepickerRef{
    opacity: 0;
}
.over-date-filter .date-picker-container{
    width: 30%;
}
.chart-note{
    color: rgba(44, 56, 74, 0.9490196078);
    font-family: var(--cui-body-font-family);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    margin-bottom: 15px;
    // margin-top: 20px;
}
.vertical-line {
    height: 33px;
    border: 1px solid #A9A9A9;
    margin-top: 24px;
}
.date-filter .movement-label{
    color: #2c384af2;
    font-family: var(--cui-body-font-family);
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
}
.field-dropdown a {
    display: flex;
    justify-content: space-between;
}
.pipeline-field-div{
    display: flex;
    flex-direction: column;
    gap: 0;
    align-items: flex-start;
    margin-top: 0 !important;
}

.custom-breadcrumbs {
    .overview-custom-breadcrumbs{
        margin-bottom: 0;
    }
    &.without-header {
        margin: 20px 0px;
    }
    ul {
        list-style: none;
        display: flex;
        padding: 0px;
        li {
            font-weight: 300;
            font-size: 11px;
            line-height: 10px;
            text-transform: capitalize;
            &::after {
                content: "|";
                width: 2px;
                margin: 0px 4px;
            }
            &:last-child::after{
                content: none;
            }
            &.active {
                color: #000000;
                font-weight: 500;
            }
        }
    }
}


// Here you can add other styles
.container{
    z-index: 10;
}
.main-container{
    flex: 1;
    width: 100%;
    z-index:1;
    background-color: $light;
    .opacity-container {
        display: none;
    }
    &.step-5 {
        align-items: flex-start !important;
        position: relative;
        @media (max-width: 900px) {
            padding: 10px;
        }
        .opacity-container {
            display: block;
            position: absolute;
            background: linear-gradient(180deg, #101257 0%, rgba(104, 107, 204, 0) 100%);
            opacity: 0.1;
            width: 100%;
            //height: 100vh;
            @media (max-width: 900px) {
                display: none;
            }
        }
    }
    .container {
        max-width: 400px;
        padding: 25px 0px;
        &.configuration{
            max-width: 472px;
            &.step-4 {
                .validation-error {
                    z-index: 0 !important;
                }
            }
        }
        &.full-width,&.step-2, &.step-3,&.step-4, &.step-9 {
            max-width: 800px;
        }
        &.step-8 {
            max-width: 400px;
        }
        &.step-5,&.step-6,&.step-7 {
            max-width: calc(100% - 60px);
            @media (max-width: 900px) {
                max-width: 100%;
            }
        }
        .row {
            padding: 0px 10px;
        }
    }
    #page-heading{
        margin-bottom: 24px;
        font-size: 23px;
        width: 500px;
        text-align: start !important;
    }
    @media (max-width: 450px) {
        padding: 10px;
        .button-container {
            flex-direction: column;
            >div {
                margin-right: 0px;
            }
            .register-link {
                margin-top: 20px;
            }
        }
    }
}

.main-container{
    &:after {
        content: "";
        //background-image: linear-gradient(323.17deg, #D9D9D9 5.77%, rgba(217, 217, 217, 0) 55.36%), url("../../../../public/images/bottom.svg");
        background: url("../../public/images/bottom.svg");
        /* IE */
        background-size:     cover;
        background-repeat:   no-repeat;
        background-position: center center; 
        width: 100%;
        position: fixed;
        height: 350px;
        bottom: 0;
        z-index: 1;
        opacity: 1;
    }
    &.no-gradient {
        &:after {
            background: none;
            display: none;
        }
    }
}

.form-error {
    margin-bottom: 10px;
    font-size: 12px;
    color: $danger-dark;
    margin-top: 4px;
    &.terms {
        margin-top: 10px;
    }
}
.sign-phn{
    padding-left: 70px;
}

.input-container {
    display: flex;
    flex-direction: column;
    .email-container {
        display: flex;
        input {
            flex: 1;
        }
        span {
            min-width: 50px;
        }
    }
    p {
        font-size: 12px;
        margin-top: 10px;
        font-weight: 300;
    }
    label {
        font-size: 16px;
        margin-bottom: 5px;
    }
    input {
        border: none;
        border-bottom: 1px solid #A9A9A9;
        background: transparent;
        outline: none;
        font-size: 14px;
        &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
            color: #A9A9A9;
            opacity: 1; /* Firefox */
            font-size: 12px;
        }
        
        &:-ms-input-placeholder { /* Internet Explorer 10-11 */
            color: #A9A9A9;
            font-size: 12px;
        }
        
        &::-ms-input-placeholder { /* Microsoft Edge */
            color: #A9A9A9;
            font-size: 12px;
        }
        &:focus{
            border-bottom: 1px solid #000000;
        }
        &:-webkit-autofill,
        &:-webkit-autofill:hover, 
        &:-webkit-autofill:focus, 
        &:-webkit-autofill:active{
            -webkit-box-shadow: 0 0 0 30px #00000000 inset !important;
        }
    }
}

.form-link {
    color: #54548C;
    text-decoration: none;
}
.button-container{
    margin-top: 50px;
    button {
        width: 150px;
        color: #ffffff;
        border-radius: 4px;
    }
}
.register-link {
    font-size: 13px;
}
.checkbox-container {
    input {
        margin: 0;
        margin-right: 10px;
    }
    label {
        margin: 0px;
    }
}

.sales-force-text {
    font-weight: 400;
    font-size: 26px;
    line-height: 44px;
    color: #000000;
    text-align: center;
    padding: 0px 50px;
}

.custom-sidebar-header {
    display: flex;
    justify-content: space-around;
    padding: 30px 0px;
    @media (max-width: 767px) {
        display: none;
    }
    &.sidebar-brand-narrow{
        text-align: center;
    }
    .fold-true {
        transform: rotate(180deg);
    }
}

.headbox{
    background-color: #F1F1F1;
    width: 100%;
    flex-wrap: wrap;
    justify-content: center;
    >div{
        border-right: 1px solid #C3C3C3;
        // height: 100px;
        flex-direction: column;
        flex: initial;
        margin: 25px 20px;
        //align-items: center;
        justify-content: space-between;
        flex-direction: row;
        padding-right: 20px;
        flex: 1;
        @media (max-width: 1024px) {
            border-right: none;
            flex: initial;
            padding-right: 0px;
            width: 300px;
        }
        &:last-child {
            border-right: none;
        }
        &:first-child {
            padding-left: 25px;
            @media (max-width: 1024px) {
                padding-left: 0px;
            }
        }
        .c-icon {
            width: 50px;
            height: 50px;
            border-radius: 50px;
            background-color: #ffffff;
            display: flex;
            justify-content: center;
            align-items: center;
        }
        h4{
            font-weight: 400;
            font-size: 14px;
            line-height: 16px;
            color: #2C2C2C;
            margin-bottom: 4px;
        }
        .amount {
            font-weight: 700;
            font-size: 22px;
            line-height: 26px;
            margin-bottom: 5px;
            &.light-blue {
                color: #008BAD;
            }
            &.dark-blue {
                color: #3436C1;
            }
            &.orange {
                color: #CB6404;
            }
            &.green {
                color: #34A853;
            }
        }
        .percentage {
            font-weight: 400;
            font-size: 12px;
            line-height: 14px;
            color: rgba(44, 44, 44, 0.6);
            margin: 0;
            .light-blue {
                color: #008BAD;
                font-weight: bold;
            }
            .dark-blue {
                color: #3436C1;
                font-weight: bold;
            }
        }
    }
}

.header-container {
    position: relative;
    width: 100%;
}

.filter-box {
    margin: 0px 50px;
    border-bottom: 1px solid #D9D9D9;
    padding: 20px 0px;
    //width: 100%;
    justify-content: space-between;
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    flex-wrap: wrap;
    @media (max-width: 1037px) {
        justify-content: center;
        align-items: center;
    }
    @media (max-width: 775px) {
        flex-direction: column;
    }
    .filter-object {
        min-width: 70px;
        padding: 0px 10px;
        .reset-button {
            // margin-left: 10px;
            cursor: pointer;
            img {
                width: 12px;
            }
        }
        @media (max-width: 1037px) {
            padding: 10px;
        }
    }
    .width-filter-object{
        min-width: 133px;
    }
    .edit-reset-div{
        display: flex;
        flex-direction: column;
        row-gap: 5px;
    }
    .filter-object-data {
        display: flex;
        flex-wrap: wrap;
        flex: 1;
        justify-content: space-between;
        @media (max-width: 1037px) {
            justify-content: center;
        }
    }
    .parent-filter-object-data{
        display: flex;
        flex: 1;
    }
    .parent-filter-object{
        display: flex;
        flex: 1;
    }
    .cursor-pointer {
        cursor: pointer;
    }
}
.filter-lable-value{
    max-width: 178px;
    word-break: break-all;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
}

.filter-overlay {
    height: 100vh;
    width: 100%;
    background: rgba(0, 0, 0, 0.3);
    position: absolute;
    .filter-container{
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.07);
        background: #ffffff;
        padding-bottom: 30px;
        .filter-container-head {
            margin: 0px 50px;
            padding: 20px 0px 10px 0px;
            border-bottom: 1px solid #D9D9D9;
            display: flex;
            justify-content: space-between;
            img {
                width: 12px;
            }
        }
        .filter-items {
            width: calc(100% - 100px);
            margin: 0px 50px;
            padding: 20px 0px;
            display: flex;
            justify-content: space-between;
            gap: 15px;
            @media (max-width: 900px) {
                flex-direction: column;
                max-height: 400px;
                overflow: scroll;
            }
            .select-container{
                width: 100%;
                @media (max-width: 900px) {
                    margin-bottom: 10px;
                }
                label {
                    font-weight: 500;
                    font-size: 13px;
                    line-height: 16px;
                    margin-bottom: 10px;
                }
                .select-box {
                    width: 95%;
                    @media (max-width: 900px) {
                        width: 100%;
                    }
                }
            }
        }
        .filter-button-container {
            display: flex;
            justify-content: space-between;
            width: calc(100% - 100px);
            margin: 0px 50px;
            gap: 20px;
            button{
                color: #ffffff;
                width: 160px;
                border-radius: 4px;
                &.secondary {
                    background: #ffffff;
                    color: #1C7FF2;
                }
            }
        }
        b{
            font-size: 13px;
            line-height: 16px;
        }
    }
}

.password-field {
    position: relative;
    input {
        width: 100%;
    }
    img {
        position: absolute;
        right: 10px;
        top: 6px;
        width: 20px;
        cursor: pointer;
        &.eye-cross{
            top: 3px;
        }
        &.internal {
            top: 13px;
            &.eye-cross{
                top: 10px;
            }
        }
    }
}

.company-list-select {
    &>div{
        //border: none;
        //border-bottom: 1px solid #A9A9A9 !important;
        background: transparent;
        outline: none;
        box-shadow: none !important;
        border-radius: 0;
    }
    

    
   
    input {
        padding: 0 10px;
    }
}

.form-label {
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #7A7A7A;
}
.form-control, .form-select {
    border: 1px solid #D8D8D8 !important;
    border-radius: 6px !important;
    height: 40px;
    &:focus {
        box-shadow: none;
        border: 1px solid #000000 !important;
    }
}

.toast {
    color: #ffffff;
    z-index: 10000;
    width: 400px;
    max-width: 100%;
    .t-container {
        padding: 20px 14px;
        position: relative;
        img {
            margin-right: 15px;
        }
        .toast-body {
            padding: 0px;
        }
        .toast-head {
            font-size: 16px;
            line-height: 16px;
            color: #FFFFFF;
            margin-bottom: 5px;
        }
        .toast-message {
            font-weight: 400;
            font-size: 14px;
            line-height: 16px;
            color: #FFFFFF;
            width: 280px;
        }
        button {
            padding: 0;
            background: transparent;
            border: none;
            position: absolute;
            right: 15px;
            top: 15px;
            img {
                margin: 0;
            }
        }
        .custom-margin{
            margin-right: 15px;
        }
        /*&.success {
            background: #101257;
        }
        &.failure {
            background: #C23933;
        }
        .toast-icon {
            margin-right: 30px;
        }
        */
    }
}

.timepicker-only {
    .react-datepicker__month-container, .react-datepicker__navigation  {
        display: none;
    }
    .react-datepicker__triangle {
        left: -4px !important;
    }
}

.capitalize {
    text-transform: capitalize
}

footer {
    width: 100%;
    text-align: center;
    background: #101257;
    color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px 0px;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    z-index: 1;
    @media (max-width: 767px) {
        padding: 12px 0px;
    }
    a {
        color: #ffffff;
        &:hover {
            color: #92accb;
        }
    }
    p {
        margin: 0;
    }
}

.modal-buttons {
    display: flex;
    gap: 20px;
    button {
        color: #ffffff;
        width: 100px;
        font-size: 14px;
    }
    .back-button {
        background: #ffffff;
        color: #1C7FF2;
    }
}


input[type='radio'] {
    accent-color: #101257;
}

input[type="checkbox"].type-1 {
    width: 16px;
    height: 16px;
}

input[type="checkbox"].type-2 {
     /* Add if not using autoprefixer */
  -webkit-appearance: none;
  appearance: none;
  /* For iOS < 15 to remove gradient background */
  background-color: #fff;
  /* Not removed via appearance */
  margin: 0;
    /* ...existing styles */
    display: grid;
    place-content: center;
  }
  
  input[type="checkbox"].type-2::before {
    content: "";
    transform: scale(0);
    transition: 120ms transform ease-in-out;
    background-image: url("../../public/icons/tick.svg");
    background-size: cover;
    background-repeat: no-repeat;
    width: 25px;
    background-position: center center;
    height: 18px;
    margin-left: 14px;
    margin-bottom: 7px;
  }
  

  [customclassname="subgroup"]{
      &.show {
          .nav-group-items {
              height: auto !important;
              display: block !important;
          }
      }
  }

  input[type="checkbox"].type-2:checked::before {
    transform: scale(1);
  }

  .nav-item .nav-link {
      padding-left: 60px !important;
  }

  .width-100 {
      width: 100%;
  }

  .filterData__menu {
      margin: 5px 0px !important;
      .filterData__menu-list {
          padding: 0px !important;
          &::-webkit-scrollbar {
            height: 2px;
            width: 5px;
            margin-top: 20px;
        }
        
        /* Track */
        &::-webkit-scrollbar-track {
            background: #d8dfeb;
        }
        
        /* Handle */
        &::-webkit-scrollbar-thumb {
            background: #101257;
            border-radius: 1px;
        }
        
        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
            background: #101257;
        }
      }
  }

  .container-404 {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      gap: 10px;
      height: 100vh;
      &.error-message {
          height: auto;
          margin-top: 100px;
      }
      @media (max-width: 767px) {
        height: calc(100vh - 70px - 1.5rem);
      }
      .heading {
          font-size: 30px;
          text-align: center;
          font-weight: 500;
          background: linear-gradient(180deg, #00CDFF, #101257 100%);
          background: -webkit-linear-gradient(180deg,#00CDFF, #101257 100%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
      }
      .sub-heading {
          font-size: 60px;
          font-weight: 600;
          background: linear-gradient(180deg, #00CEFF, #101257 100%);
          background: -webkit-linear-gradient(180deg, #00CEFF, #101257 100%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          text-align: center;
          @media (max-width: 500px) { 
            font-size: 35px;
          }
      }
  }

  .hide-half {
      width: var(--cui-sidebar-narrow-width);
  }

  .back-icon {
    width: 26px;
    margin-bottom: 3px;
    margin-right: 30px;
    cursor: pointer;
  }

  .tooltip {
    font-family: var(--cui-body-font-family);
    font-size: 15px;
  }
  .modal-body {
      min-height: 120px;
      margin-top: -27px;
      padding-top: 27px;
  }

  .flex-direction-column {
      flex-direction: column;
      gap: 10px;
  }

  .table-container {
    table {
        border: 1px solid #d8dbe0;
        border-collapse: collapse;
        table-layout: fixed;
        td, th {
            border: 1px solid #d8dbe0;
            font-size: 13px;
            padding: 1px 3px;
            border-collapse: collapse;
            position: relative;
            &.width-small {
                width: 105px !important;
            }
            &.width-medium {
                width: 140px !important;
            }
            &.width-large {
                width: 260px !important;
            }
            tbody {
                max-height: 400px;
                overflow: auto;
            }
            .icon-container {
                //position: absolute;
                //right: 5px;
                //top: 50%;
                //transform: translateY(-50%);
            }
        }
        th {
            padding: 5px 3px;
            background: #787878;
            color: #fff;
            .heading-container {
                display: flex;
                gap: 5px;
                align-items: center;
                width: 100%;
                justify-content: space-between;
            }
        }
    }
    // .style-sorting-arrow {
    //     cursor: pointer;
    //     &.desc {
    //         transform: rotate(180deg);
    //     }
    // }
    .total-amount-row {
        td {
            padding: 5px 3px;
        }
    }
}

.sidebar-narrow-unfoldable {
    .sidebar-footer {
        visibility: visible !important;
        opacity: 1 !important;
        span, svg{
            display: none;
        }
        .avatar {
            margin-top: 50px;
            margin-left: 14px;
        }
    }
    &:hover {
        .sidebar-footer {
            visibility: visible !important;
            opacity: 1 !important;
            span, svg{
                display: inline;
            }
            .avatar {
                margin-right: 10px !important;
                margin-left: 0;
                margin-top: 0px;
            }
        }
    }
}

::-webkit-scrollbar {
    height: 5px;
    margin-top: 20px;
    width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #d8dfeb;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #101257;
    border-radius: 1px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #101257;
}