// If you want to override variables do it here
@import "variables";

$enable-ltr: true;
$enable-rtl: true;

// Import CoreUI for React components library
@import "@coreui/coreui/scss/coreui";

// Import Chart.js custom tooltips styles
@import "@coreui/chartjs/scss/coreui-chartjs";

@import "layout";
@import "example";

// If you want to add custom CSS you can put it here.
@import "custom";
@import "_edit.scss";
@import "forecast";
@import "../../src/views/myAccount/style";
// @import "../../src/views/pages/ConfigureSteps/style";

.nav-link {
    font-size: 14px !important;
    padding-left: 50px !important;
    padding-top: 8px !important;
    padding-bottom: 8px !important;
}

.nav-link.nav-group-toggle {
    padding-left: 21px !important;
    font-size: 16px !important;
    width: 200px;
    .sidebar-image-container {
        width: 50px;
        img {
            //width: 18px !important;
            //margin-right: 28px;
            height: 20px;
        }
    }
}

.nav-group {
    &[customclassname="subgroup"]{
        padding-left: 0px;
        a {
            padding-left: 60px !important;
        }
        .nav-item a {
            padding-left: 80px !important;
        }
        .nav-group-toggle::after {
            margin-left: 18px !important;
        }
    }
}
.style-sorting-arrow {
    cursor: pointer;
    &.desc {
        transform: rotate(180deg);
    }
}
.cursor-pointer{
    cursor: pointer;
}
.cursor-disabled{
    cursor: not-allowed !important;
}
.weighted-width{
    width: 400px;
}
.text-underline{
    text-decoration: underline;
    color: #101257;
}
.none{
    display: none;
}
.border-class{
    // border-bottom: 1px solid black !important;
}
.rep-table {
    th:nth-child(2),
     td:nth-child(2) {
       left: 179px !important;
    }
    td:nth-child(2),td:nth-child(1){
        font-size: 14px !important;
    }
    tfoot{
        td:nth-child(2),td:nth-child(1){
            font-size: 14px !important;
        }
    }
    th:nth-child(1),
     td:nth-child(1) {
        max-width: 180px !important;
        min-width: 180px !important;
        width: 180px !important;
    }
}
.freeze-row {
    width: 100%;
    max-height: 1100px;
    overflow: auto;
    border: 1px solid #777777;
    table {
        border-spacing: 0;
    }
    
     th {
        z-index: 10;
        border-left: none;
        border-right: 1px solid #bbbbbb;
        padding: 5px;
        width: auto;
        min-width: 140px;
        position: sticky;
        top: 0;
        // background: #727272;
        // color: #e0e0e0;
        font-weight: normal;
    }
    
     td {
        border-left: none;
        border-right: 1px solid #bbbbbb;
        border-bottom: 1px solid #bbbbbb;
        padding: 5px;
        width: 80px;
        min-width: 80px;
    }
    
     th:nth-child(1),
     td:nth-child(1) {
        position: sticky;
        left: 0;
        width: auto;
        z-index: 1;
        min-width: 150px;
    }
    th{
        position: sticky !important;
        z-index: 9;
        background-color: #F6F6F5;
    }
     th:nth-child(2),
     td:nth-child(2) {
        position: sticky;
        /* 1st cell left/right padding + 1st cell width + 1st cell left/right border width */
        /* 0 + 5 + 150 + 5 + 1 */
        left: 149px;
        width: auto;
        min-width: 150px;
        z-index: 1;
    }
    .quota-table {
        th:nth-child(2),
     td:nth-child(2){
        left: 260px;
     }
    }
    
     td:nth-child(1),
     td:nth-child(2) {
        background: #f8f7f7;
    }
    
     th:nth-child(1),
     th:nth-child(2) {
        z-index: 11;
    }
} 
.admin-table .manager-table-tr th:nth-child(1) {
    background: #E6E6E6;
}
.admin-table .manager-table-tr th:nth-child(2) {
    background: #E6E6E6;
}
.upper-table {
     tr th, td{
        width: 100%;
     }
}