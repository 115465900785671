.configure-containerr{
    .input-container{
        align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 60px;
    }
    .weighted-btn{
        border: none;
        background: hsl(212, 89%, 53%);
        height: 43px;
        border-radius: 4px;
        color: #fff;
        padding: 0 30px;
    }
    .weighted-btn-div{
        width: 90%;
        display: flex;
        justify-content: flex-end;
        margin-bottom: 10px;
    }
    .custom-select, input{
        border: 1px solid #d8d8d8;
        border-radius: 6px;
        font-size: 14px;
    font-weight: 400;
    height: 40px;
    line-height: 26px;
    padding: 0 10px;
    width: 240px;
    }
    .content-heading{
    //     color: #101257;
    // font-size: 22px;
    // font-weight: 400;
    // line-height: 32px;
    // margin-bottom: 42px;
    // text-align: center;
    }
    .full-width{
        max-width: 100%;
    width: 520px;
    }
    .input-container input{
        background: transparent;
    }
    .input-container .field-container {
        display: flex;
        flex-direction: column;
    }
    .action-buttons {
        text-align: center;
    }
    .action-buttons button{
        border-radius: 4px;
    color: #fff;
    font-size: 16px;
    font-weight: 500;
    height: 40px;
    line-height: 26px;
    min-width: 150px;
    text-align: center;
    width: auto;
    }
    .input-container .field-container.radio{
        align-items: center;
    flex-direction: row-reverse;
    justify-content: flex-end;
    margin-right: 60px;
    }
    .input-container .field-container.radio input{
        height: 22px;
        width: 22px;
        margin-right: 20px;
    }
    .field-container.radio label{
        color: #101257;
    font-size: 20px;
    font-weight: 400;
    line-height: 26px;
    margin: 0;
    }
}
#timepout-text{
    text-align: center;
        color: #101257;
    font-size: 22px;
    font-weight: 400;
    margin-bottom: 15px;
}
.edit-full-width{
    width: 520px;
    z-index: 4 !important;
}
.fiscal-parent{
    position: relative;
    z-index: 2;
}

.css-1qdn3vb-MenuPortal {
    z-index: 3 !important;
}