.forecast-main{
    height: 65vh;
    border: 1px solid #EEEEEE;
    display: flex;
    // flex-direction: column;
    align-items: center;
    justify-content: center;
}
.forecast-inner{
    width: 350px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 25px;
}
.organization-hierarchy{
    color: #2C384A;
font-size: 22px;
font-weight: 600;
line-height: 24px;
text-transform: capitalize;
}
.organization-text{
    color: #2C384A;
font-size: 14px;
font-style: normal;
font-weight: 300;
line-height: 24px;
}
.forecast-btn{
    color: #FFF;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: 16px;
letter-spacing: 0.28px;
height: 35px;
}
.admin-name{
    color: #2C384A;
font-size: 22px;
font-style: normal;
font-weight: 300;
line-height: 24px;
display: flex;
}
.admin-main-name{
    font-weight: 600;
    text-transform: capitalize;
}
.commit-text{
    color: #2C384A;
font-size: 14px;
font-style: normal;
font-weight: 300;
line-height: 24px;
margin-bottom: 35px;
}
.weekly-commits-div{
    display: flex;
    justify-content: space-between;
    background-color: #F1F1F1;
    padding: 20px;
}
.manager-commit{
    background-color: #ECECFF;
    height: 39px;
}
.common-commit-div{
    background-color: #00CDFF26;
    height: 39px;
    align-items: center;
}
.admin-inputs-labels{
    display: flex;
    column-gap: 15px;
    padding: 17px;
}
.admin-inputs-labels div{
    width: 25%;
}
.admin-inputs-labels div input{
    // max-width: 360px;
    width: 100%;
    height: 35px;
    border-radius: 4px;
border: 1px solid #A9A9A9;
padding-left: 10px;
font-size: 13px;
}
.new-weekly-row{
    background: #ECECFF;
    height: 39px;
    width: 100%;
    td{
        background: #ECECFF !important;
        border-color: #ECECFF !important;
    }
}
.forecast-error-msg{
    font-size: 13px;
    margin-left: 5px;
    margin-top: 4px;
    color: red;
}
.error-border{
    border: 1px solid red;
}
.numeric-div{
    width: 100% !important;
    position: relative;
}
.numeric-dollar{
    position: absolute;
    font-size: 13px;
    left: 10px;
    top: 6px;
}
.numeric-div input{
    padding-left: 16px !important;
}
.Admin-submit-btn{
    display: flex;
    justify-content: flex-end;
    padding-right: 17px;
    margin-bottom: 25px;
}
.weekly-main-div{
    border-radius: 4px;
    border: 1px solid #EEE;
}
.weekly-commits-div div{
    color: #2C384A;
font-size: 16px;
font-weight: 500;
}
.admin-inputs-labels label {
    color: #2C384A;
font-size: 13px;
font-weight: 400;
line-height: 16px;
margin-bottom: 10px;
}
.admin-quater{
    display: flex;
    column-gap: 40px;
    padding-top: 20px;
}
.quater-lable{
    color: #000;
    font-size: 14px;
    font-weight: 400;
}
.quater-lable span{
    color: #101257;
    font-weight: 700;
}
.admin-table .user-table{
    background-color: #787878;
    color: #FFF !important;
    font-size: 13px;
    font-weight: 600;
    line-height: 19px;
}
.admin-table .manager-table{
    background-color: #F6F6F5;
}
.admin-table{
    thead tr{
    color: #FFF !important;
    font-size: 13px;
    font-weight: 600;
    line-height: 19px;
    column-gap: 15px;
    th {
        padding: 5px 3px !important;
    }
    }
    .manager-table-tr {
        color: #101257 !important;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 19px;
        display: flex;
        column-gap: 0;
    }
    tbody tr{
    color: #2C384Af2;
    font-size: 13px;
    font-weight: 400;
    border-bottom: 1px solid #F1F1F1 ;
    }
}
.admin-table tr th,td{
    border: 1px solid #d8dbe0;
    font-size: 13px;
    padding: 1px 3px;
    border-collapse: collapse;
    position: relative;
}
.manager-table-tbody, .table-footer, .new-weekly-row, .date-inputs, .submit-row{
    display: flex;
    column-gap: 0;
}
.admin-table .manager-table-tr th {
    border: none !important;
    padding: 13px 8px !important;
    font-size: 14px;
}
.admin-table .manager-table-tbody td {
    border: none;
    padding: 13px 8px !important;
    color: #2C384A;
    font-size: 16px;
    font-weight: 400;
}
.admin-table .table-footer td{
    border: none;
}
.total-span{
    color: #2C384A;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
}
.table-footer .upper-arrow-div span{
    font-weight: 700;
}
.table-footer .down-arrow-div span{
    font-weight: 700;
}
.admin-table .manager-table-tbody td:first-child, .admin-table .manager-table-tr th:first-child, .admin-table .table-footer td:first-child {
    padding-left: 14px !important;
    display: flex;
    align-items: center;
}
.admin-table .manager-table-tbody td:nth-child(2), .admin-table .manager-table-tr th:nth-child(2), .admin-table .table-footer td:nth-child(2) {
    display: flex;
    align-items: center;
}
.reload-icon{
    margin-right: 40px;
}
.disables-input{
    // background-color: #F1F1F1;
    background: none !important;
    border: none !important;
    display: flex;
    // justify-content: center;
    margin: 7px 0px 7px 0px;
    span{
        min-width: 35px;
        margin-left: -3px;
        font-size: 16px;
        font-weight: 500;
    }
}
.tooptip-btn{
font-size: 12px;
font-style: normal;
font-weight: 400;
}
.scroll-commits{
    display: flex;
    overflow: auto;
    width: 100%;
}
.amount-error{
    color: #DB4437;
text-align: center;
font-size: 14px;
font-weight: 500;
line-height: 24px;
margin: 20px 0;
}
.new-amount-error{
    text-align: left;
    margin: 5px 0;
}
.error-input{
    height: 110px;
    width: 100%;
    border-radius: 4px;
    border: 1px solid #A9A9A9;
    background: #FFF;
}
.error-input{
    // color: rgba(44, 56, 74, 0.40);
    color: #2C384A;;
font-size: 13px;
font-weight: 500;
padding: 10px;
}
.admin-table tr{
    width: 100%;
}
.admin-table tr th,td{
    width: 14%;
}
.rep-table tr th, td{
    width: 18%;
}
.table-quater-name{
    width: 24% !important;
}
.table-stage-name{
    width: 23% !important;
}
.invitation-main-div{
    width: 100%;
    border-radius: 4px;
    border: 1px solid #EEE;
    background: #FAFAFA;
    .invitation-footer {
        margin: 0px 30px 20px;
        border-top: 1px solid #e0e0e0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 20px;
        padding-top: 20px;
    }
}
.invite-member{
    background-color: #ECECFF;
    color: #2C384A;
    font-size: 16px;
    font-weight: 500;
    padding: 15px;
}
.inviteall-div{
    display: flex;
    align-items: center;
    margin-right: 30px;
}
.inviteall-div .common-checkbox{
    cursor: pointer;
}
.select-text{
    margin-left: 10px;
    cursor: pointer;
}
.select-main-div{
    display: flex;
    justify-content: space-between;
    padding: 15px 30px;
}
.select-text{
    color: #2C384A;
    font-size: 15px;
    font-style: normal;
    font-weight: 300;
}
.invite-btn{
    border-radius: 4px;
background: #1C7FF2;
    height: 35px;
    width: 100px;
    color: #FFF;
text-align: center;
font-size: 14px;
font-weight: 500;
line-height: 16px;
letter-spacing: 0.28px;
border: none;
}

.invite-btn:hover{
    background-color: transparent;
    color: #1C7FF2;
    cursor: pointer;
    border: 2px solid #1C7FF2;
    transition-duration: 500ms;
    transition-delay: 300ms;
}
.disabled {
    cursor:not-allowed !important;
}
.ship-btn{
    border-radius: 4px;
    border: 1px solid #E3E3E3;
    background: #FFF;
    width: 116px;
    height: 35px;
    color: #1C7FF2;
text-align: center;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: 16px;
letter-spacing: 0.28px;
}
.line-hr{
    border-bottom: 1px solid #E0E0E0;
    margin: 0 30px;
    margin-bottom: 20px;
}
.all-tiles{
    display: flex;
    margin: 0 20px;
    column-gap: 15px;
    flex-wrap: wrap;
    row-gap: 22px;
    padding-bottom: 2rem;
}
.tiles-mian{
    display: flex;
    align-items: center;
    border-radius: 4px;
    border: 1px solid #E0E0E0;
    background: #FFF;
    width: 32.3%;
    justify-content: space-between;
    padding: 13px 10px;
    min-width: 330px;
    @media (max-width: 1360px) {
        width: 40%;
    }
}
.tiles-invite-btn{
    border-radius: 4px;
    border: 1px solid #1C7FF2;
    background: #FFF;
    color: #1C7FF2;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0.28px;
    height: 30px;
}
.tiles-name-div{
    // display: flex;
    //max-width: 131px;    
}
.tiles-name{
    color: #2C384A;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    text-transform: capitalize;
}
.tiles-position{
    color: #2C384A;
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: 24px
}
.common-checkbox{
    width: 15px;
    height: 15px;
    cursor: pointer;
}
.avatar-img{
    margin-left: 10px;
    margin-right: 10px;
    width: 50px;
}
.reset-popup{
    .modal-header{
        border-bottom: none !important;
    }
    .main-title{
        padding-top: 40px;
        color: #101257;
        text-align: center;
        font-size: 22px;
        font-weight: 600;
        line-height: 28px;
    }
    .quota-text{
        margin-top: 15px;
        text-align: center;
    }
    .modal-text{
        color: #2C384A;
        text-align: center;
        font-size: 14px;
        font-weight: 300;
        margin: 25px 0;
    }
    .modal-button{
        border-radius: 4px;
        border: 1px solid #1C7FF2;
        color: #1C7FF2;
        text-align: center;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 16px;
        letter-spacing: 0.28px;
        width: 100px;
        height: 35px;
        background: #FFF;
    }
    .yes-btn{
        margin-left: 12px;
        background: #1C7FF2;
        color: #FFF;
    }
    .button-div{
        text-align: center;
        margin-bottom: 24px;
    }
    @media (min-width: 576px){
        .modal-dialog {
            max-width: 350px;
        }
    }

}
.quota-popup {
    .main-title {
        font-size: 20px !important;
    }
    @media (min-width: 576px){
        .modal-dialog {
            max-width: 450px !important;
        }
    }
}
.weekly-main-div-table{
    padding-bottom: 40px;
}
.smaller{
    color: #DB4437 !important;
}
.bigger{
    color: #34A853 !important;
}
.disable-date{
    color: #2C384A;
    font-size: 14px;
    font-weight: 400;
    // margin-right: 18px;
    display: flex;
    // justify-content: center;
}
.disable-upper-div{
    // margin: 0 auto;
}
.input-date{
    margin-right: 10px;
}
.lower-span{
    display: flex;
    // margin-left: 88px;
    height: 50px;
    align-items: flex-start;
    column-gap: 5px;
}
.slider-arrow{
    margin-right: 5%;
}
.disable-lower-value{
    padding-top: 14px;
}
.disable-value{
    color: #2C384A;
    font-size: 14px;
    font-weight: 500;
    opacity: 0.6;
    padding-bottom: 14px;
}
.arrow-value{
    display: flex;
    align-items: flex-end;
    height: 50px;
    column-gap: 5px;    
}
.upper-arrow-div{
    display: flex;
    align-items: flex-end;
    // height: 30px;
    padding-left: 2px;
    column-gap: 5px;
    height: 20px;
    span{
        color: #515050;
        font-size: 14px;
    }
}
.enable-upper-div, .enable-lower-div {
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
}
.enable-upper-div{
    height: 50px;
    column-gap: 5px;
    position: relative;
    width: 100%;
    // margin-left: 80px;
    input {
        margin-bottom: 13px;
        padding-left: 12px !important;
    }
    .static-dollar{
        position: absolute;
        top: 12.5px;
        left: 30px;
        font-size: 13px;
    }
}
.enable-lower-div{
    // margin-left: 80px;
    height: 50px;
    column-gap: 5px;
    margin-bottom: 10px;
    position: relative;
    input{
        margin-top: 13px;
        padding-left: 12px !important;
    }
    .static-dollar{
        position: absolute;
        bottom: 12px;
        left: 30px;
        font-size: 13px;
    }
}
.set-width{
    min-width: 210px !important;
}
.down-arrow-div{
    display: flex;
    align-items: baseline;
    // height: 30px;
    padding-left: 3px;
    column-gap: 5px;
    span{
        color: #515050;
        font-size: 14px;
    }
}
.date-label{
    background-color: #F1F1F1;
    height: 35px;
    margin: 0 20px;
    padding: 0 20px;
    margin-top: 10px;
}
.date-inputs div input {
    width: 90%;
    height: 30px;
    border-radius: 4px;
    border: 1px solid #A9A9A9;
    padding-left: 10px;
    font-size: 13px;
    &:focus-visible {
        // border: none;
        outline: none;
    }
}
.forecast-arrow-img{
    height: 10px;
    margin-left: 5px;
    margin-bottom: 3px;
}
.ceiling-div{
    display: flex;
    flex-direction: column;
    padding-left: 5px;
}
.new-ceiling-div{
    display: table-cell !important;
    border-right: 1px solid white !important;
    border-left: 1px solid white !important;
}
.new-ceiling-div-name{
    border-right: 1px solid#f8f7f7 !important;
}
.new-border{
    border-bottom: 1px solid #f8f7f7 !important;
    display: flex !important;
    align-items: center;
    border-right: 1px solid #f8f7f7 !important;
    border-left: 1px solid #f8f7f7 !important;
}
.row-vector-img{
    margin-bottom: 7px;
}
.low-row-vector-img{
    margin-top: 7px;
}
.white-border{
    border-bottom: 1px solid white !important;
}
.tab-input{
    width: 161px !important;
}
.error-border{
    border: 1px solid red !important;
}
.arrow-height{
    width: 12px !important;
    height: 15px !important;
}
.rep-table th:nth-child(1).rep-weekly-commits{
    padding: 0 !important;
    width: 1px !important;
    min-width: 1px !important;
}
.rep-table th:nth-child(2).rep-weekly-commits{
    left: 1px !important;
}
.static-padding{
    padding-bottom: 2px;
}
.forecast-input{
    width: 31% !important;
    min-width: 200px !important;
}
.width-inherit{
    width: inherit;
}
.last-ele:last-child{
    width: 31%;
    min-width: 200px!important;
}
#last-ele:last-child{
    width: 31%;
    min-width: 200px!important;
}
.static-padding{
    padding-top: 0.1rem !important;
}
.rep-padding{
    padding-top: 1rem !important;
}
.seiling-span{
    display: flex;
    align-items: flex-end;
}
.vector-img{
    height: 28px;
    width: 21px;
}
.forecaste-span{
    display: flex;
    min-width: 56px;
}
.no-found{
    text-align: center;
}
.admin-table .user-table tr th div {
    display: flex;
    width: 100%;
    justify-content: space-between;
}
.select-freq{
    color: #2C384A;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    margin-bottom: 5px;
    margin-top: 10px;
}
.dynamic-clr{
    font-weight: 600 !important;
}
.select-label{
    margin: 0;
}
.upper-dropdown {
    .dropdown-divider{
        margin: 0;
    }
    .dropdown-menu.show{
        padding: 0;
        .dropdown-item{
            height: 33px;
            display: flex;
            align-items: center;
        }
    }
    .previous-quaters{
        background-color: #F6F6F5;
    }
}
.common-dropdown{
    margin: 0;
    align-items: center;
    column-gap: 5px;
}
.forecaste-tabs{
    .nav-tabs .nav-link{
        padding-left: 0 !important;
        padding: 0;
        // width: 144px;
        height: 45px;
        color: rgba(0, 0, 0, 0.60);
        font-size: 14px;
        font-weight: 400;
        border: 1px solid #ded8d8;
        padding: 0 5px !important;
        width: max-content;
    }
    .nav-link:hover{
        background: #e3e2e2;
    }
    .nav-tabs .active{
        padding-left: 0 !important;
        padding: 0;
        background: #ECECFF;
        border-bottom: 1px solid #ECECFF;
        height: 45px;
        color: #101257;
        font-size: 14px;
        font-weight: 600;
        padding-left: 5px !important;
        padding-right: 5px !important;
    }
    .nav-tabs{
        border-bottom: 6px solid #ECECFF;
        overflow-x: scroll;
        flex-wrap: nowrap !important;
        height: 51px;
        overflow-y: hidden;
        // column-gap: 20px;
    }
    .nav-tabs::-webkit-scrollbar {
        display: none;
    }
    .tab-content > .active{
        border: none !important;
    }
}
.forecaste-popup{
    .btn-close{
    display: none;
    }
    h5{
        text-align: center;
        width: 100%;
        color: #101257;
        font-size: 22px;
        font-weight: 600;
    }
    .modal-header{
        padding-top: 40px;
        border-bottom: none;
    }
    .modal-body{
        border-bottom: none;
    }
    .modal-footer{
        justify-content: center;
        padding-bottom: 40px;
        border-top: none;
    }
    .btn-secondary{
        border-radius: 4px;
        border: 1px solid #1C7FF2;
        background: #FFF;
        color: #1C7FF2;
        height: 35px;
        width: 100px;
    }
    .btn-primary{
        border-radius: 4px;
        background: #1C7FF2;
        color: white;
        border: none;
        border-radius: 4px;
        height: 35px;
        width: 100px;
    }
}
.hover-btn{
    @apply hover:bg-blue-600 hover:text-white transition-all duration-500 delay-150
}